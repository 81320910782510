import { Component, OnInit, HostBinding, Injector } from "@angular/core";
import {
  faInbox,
  faWallet,
  IconDefinition,
  faUsers,
  faFileInvoice,
  faEnvelopeOpenText,
  faBinoculars,
  faHistory,
  faBook,
  faLightbulb,
  faCaretRight,
  faCaretUp,
  faSearch,
  faTimes,
  faAddressCard,
  faEnvelope,
  faDog,
  faPhone,
  faCopy,
  faTicketAlt,
  faComment,
  faCommentDots,
  faPeopleArrows,
  faBuilding,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  IConversation,
  EConversationStatus,
  EConversationStartedBy,
  EConversationPriority,
} from "src/app/interfaces/conversation";
import { CallCenterOperatorStatusService } from "src/app/services/call-center-operator-status.service";
import { QueueService } from "../services/queue.service";
import { BasePage } from "src/app/components/base-page/base-page";
import { ConversationsService } from "../services/conversations.service";
import { IConversationMessage } from "src/app/interfaces/conversation-message";
import { environment } from "src/environments/environment";
import { FormGroup, Validators } from "@angular/forms";
import { ContactsService } from "../../contacts/services/contacts.service";
import { IContact } from "src/app/interfaces/contact";
import {
  ECPCallCenterService,
  SearchOnEcpDatabaseParamsModel,
} from "../services/ecp-call-center.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { QueuesService } from "../services/queues.service";
import { IUser } from "src/app/interfaces/user";
import { IPhone } from "src/app/interfaces/phone";
import { OperatorsService } from "../services/operators.service";
import * as moment from "moment-timezone";
import io from "socket.io-client";
import "regenerator-runtime/runtime.js";
import { IConversationQueue } from "../../../interfaces/conversation-queue";
import { TicketsService } from "../services/tickets.service";
import voiceSipConnector from "../voice-sip-connector";
import { Invitation, SessionState } from "sip.js/lib/api";
import { IncomingCallParams } from "./components/receptive-call/receptive-call.component";
import { InviterWithPeerConnection } from "./components/calls-dialer/calls-dialer.component";
import { STATUS_TYPE } from "src/app/interfaces/operator-status-log-v2";
import { EventBusService } from "ng-simple-event-bus";
import { VoiceHelper } from "../../../helpers/voice-helper";
import validator from "validator";
import { IOperator } from "src/app/interfaces/operator";
import { Monitoring } from "src/app/interfaces/monitoring";

type NewConversationTab =
  | "search"
  | "new"
  | "transfer"
  | "anon"
  | "ativo-email"
  | "import";

@Component({
  selector: "app-operator",
  templateUrl: "./operator.component.html",
  styleUrls: ["./operator.component.scss"],
})
export class OperatorComponent extends BasePage implements OnInit {
  @HostBinding("class") classAttribute;
  tab: string;
  loading: boolean;
  faInbox2: IconDefinition;
  faCopy = faCopy;
  faPhone = faPhone;
  faComment = faComment;
  faCommentDots = faCommentDots;
  faSearch = faSearch;
  faTimes = faTimes;
  faCaretRight = faCaretRight;
  faAddressCard = faAddressCard;
  faEnvelope = faEnvelope;
  faCaretUp = faCaretUp;
  faWhatsapp = faWhatsapp;
  faTicketAlt = faTicketAlt;
  faDog = faDog;
  faInbox = faInbox;
  faWallet = faWallet;
  faFileInvoice = faFileInvoice;
  faHistory = faHistory;
  faBook = faBook;
  faLightbulb = faLightbulb;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faBinoculars = faBinoculars;
  faCheckSquare = faCheckSquare;
  faPeopleArrows = faPeopleArrows;
  users = faUsers;
  faBuilding = faBuilding;

  conversations: Array<IConversation>;
  queue: Array<IConversation>;
  queues: Array<IConversationQueue>;
  currentConversation: IConversation;
  actualPhonesUpdated: any[];
  ws: any;
  contactForm: FormGroup;
  contactSearchForm: FormGroup;
  createContactForm: FormGroup;
  searchResults: Array<IContact>;
  showSearchResults: boolean;
  showTabulationForm: boolean;
  user: IUser;
  currentQueue: string;
  ecpIsFromGenesys: boolean;
  autostartCalls: boolean;
  loadingTabulation: boolean;
  operator: IOperator;
  searching: boolean;
  loadingConversations: boolean;
  customFieldsForm: any;
  startConversationTab: NewConversationTab = "search";
  organizationConversationTab: string;
  creatingConversation: boolean;
  currentIncomingCall: IncomingCallParams;
  currentInviter: Invitation;
  shouldByeCall: boolean;
  showCancelConversation: boolean;
  shouldReloadQueues: boolean;
  isLoadingQueue: boolean;
  sipConnected: boolean;

  valueEmitterFromCustomerDetails: {};
  invoiceToAttach: any;
  uniqueIdFromSwitch: string;

  voiceHelper: VoiceHelper;
  inboundPhones: { inbounded: boolean; phone: any[] };

  searchData: string | null = null;
  phoneFromReceptiveCall: any;
  attemptLink: number = 0;

  monitoringAnswered: { answered: boolean; section: string };
  currentMonitoring: Monitoring;

  constructor(
    injector: Injector,
    private operatorStatusService: CallCenterOperatorStatusService,
    private queueService: QueueService,
    private queuesService: QueuesService,
    private conversationsService: ConversationsService,
    private ticketsService: TicketsService,
    private contactsService: ContactsService,
    private ecpService: ECPCallCenterService,
    private router: Router,
    private operatorsService: OperatorsService,
    private location: Location,
    private event: EventBusService
  ) {
    super(injector);
    this.sipConnected = false;
    this.classAttribute = "container-fluid p-0";
    this.operatorStatusService.toggleShowStatusToggler(true);

    const voiceServerConfig =
      this._settingsService.getLocallyVoiceServerConfig();
    this.voiceHelper = VoiceHelper.create(
      this._organization,
      voiceServerConfig
    );

    this.contactSearchForm = this._formBuilder.group({
      query: [null, Validators.required],
      database: [null],
    });

    this.createContactForm = this._formBuilder.group({
      installation_number: [null, Validators.required],
    });

    this.contactForm = this._formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      cpf: [null, Validators.required],
      rg: [null, Validators.required],
      birthdate_at: [null, Validators.required],
      phones: this._formBuilder.array([]),
      emails: this._formBuilder.array([]),
      addresses: this._formBuilder.array([]),
      custom_fields: [null],

      // ECP
      naturalness: [null, Validators.required],
      marital_status_id: [null, Validators.required],
      is_pne: [null],
      deathdate_at: [null],
      bond_number: [null],
      admission_at: [null],
      is_militant: [null],
      nationality: [null],
      can_send_magazine: [null],
      can_send_mail: [null],
      can_call: [null],
      can_email: [null],
      readmission_at: [null],
      occupations: this._formBuilder.array([]),
      professionalBackgrounds: this._formBuilder.array([]),
      divorce_at: [null],
      wedding_property_regime: [null],

      // Auphafood
      auphafood_expectations: [null],
      auphafood_origin: [null],

      // CPFL
      company_code: [null],
      pn_code: [null],
      group_code: [null],
      address_type: [null],

      // ENEL - CPFL
      installation_number: [null],

      // THERMAS
      associate_at: [null],
      active: [null],
      contact_category: [null],
      profession: [null],
      salary: [null],
      previsul: [null],
      holding_type: [null],
      holding_code: [null],
      external_code: [null],
      gender: [null],
      thermas_origin: [null],

      // MEDLEVENSOHN
      medlevensohn_name: [null],
      medlevensohn_type: [null],
      medlevensohn_work_line: [null],
      medlevensohn_person_type: [null],
      person_type: [null],
      name: [null],
      type: [null],
      work_line: [null],
      business_type: [null],
      business_work_line: [null],
      order_emitter: [null],
      business_order_emitter: [null],
      ein: [null],
      business_origin: [null],
      origin: [null],
      business_observations: [null],
      marital_status: [null],
      observations: [null],
      business_name: [null],
      business_cnpj: [null],

      // CAML
      registration: [null],
      category: [null],
      title: [null],
    });
  }

  onMonitoringAnswered(answered) {
    this.monitoringAnswered = answered;
  }

  onCurrentMonitoring(monitoring) {
    this.currentMonitoring = monitoring;
  }

  /**
   * Angular lifecycle event
   * Load queues and show in progress conversations
   */

  async handleConnectSip() {
    this.user = this._auth.getUser();

    const operator = await this.operatorsService.mePromise(
      this._organization.id
    );
    this.operator = operator?.data;

    const conversations = await this.getQueue("me", this.user.id);
    this.conversations = conversations
      .flat()
      .map((el) => this.conversationsService.parseConversation(el));

    const canUseInboundCalls = this.voiceHelper.canUseVoiceModule("inbound");
    if (canUseInboundCalls) {
      navigator.mediaDevices.getUserMedia({ audio: true });
      await this.connectSipServer().catch((error) => {
        console.error("[SIP] Error while connecting", error);
      });
    }
  }

  async ngOnInit() {
    try {
      this.loadingConversations = true;
      this.customFieldsForm = {};

      const operator = await this.operatorsService.mePromise(
        this._organization.id
      );
      this.operator = operator?.data;

      const ecpIsFromGenesys = localStorage.getItem("@ecp/operator-mode");

      if (ecpIsFromGenesys && ecpIsFromGenesys === "limited") {
        this.ecpIsFromGenesys = true;
      }

      this.handleConnectSip();

      this.tab = "customer-details";
      this.currentQueue = "me";
      this.user = this._auth.getUser();

      const conversations = await this.getQueue("me", this.user.id);
      this.loadingConversations = false;
      this.conversations = conversations
        .flat()
        .map((el) => this.conversationsService.parseConversation(el));
      this.currentConversation = this.getCurrentConversation();

      await this.loadQueues();

      const operatorCanReceiveCall =
        await this.operatorIsEligibleToReceiveCalls();

      if (this.currentConversation) {
        this.getCustomFieldsFormFromConversation();
      }

      this.connectWs();

      const conversationId = await this.getId();

      if (conversationId) {
        const conversation = await this.getConversationById(conversationId);

        if (conversation) {
          this.currentConversation = conversation;
          this.getCustomFieldsFormFromConversation();
        } else {
          this.onOpenContact({ id: conversationId });
        }
      }

      this.watchConversationParams();
      // escuta o evento de mudança de status do (operator-status-modal.component)
      this.event.on("operatorStatus", (payload) => (this.operator = payload));
    } catch (error) {
      this.loadingConversations = false;

      console.error(error.message);
    }
  }

  invoicesFromDetails(valueEmitter: any) {
    if (valueEmitter) {
      this.tab = valueEmitter.tab;
      this.invoiceToAttach = valueEmitter.blob;
    } else {
      this.tab = "";
      this.invoiceToAttach = {};
    }
  }

  async nextDistribution(): Promise<IConversation> {
    const { data: conversation } =
      await this.conversationsService.getNextDistribution(
        this._organization.id,
        {
          queueId: this.currentQueue,
        }
      );
    return conversation;
  }

  async startDistribution() {
    const conversation = await this.nextDistribution();
    this.onToggleConversation(conversation);
  }

  setGlobalIncomingCall(bool: boolean = true) {
    const currentCall = bool ? this.currentIncomingCall : bool;
    this.event.trigger("IncomingCall", currentCall);
  }

  async watchConversationParams() {
    this._activatedRoute.paramMap.subscribe(async (paramMap) => {
      const conversationId = paramMap.get("id");

      if (conversationId) {
        const conversation = await this.getConversationById(conversationId);

        if (conversation) {
          this.currentConversation = conversation;
          this.getCustomFieldsFormFromConversation();
        }
      }
    });
  }

  async connectSipServer() {
    const voiceServerConfig =
      this._settingsService.getLocallyVoiceServerConfig();
    const servers = await this.conversationsService.getTwilioNTSToken(
      this._organization.id
    );
    await voiceSipConnector.connect(
      {
        extensionPassword: this.operator?.extension_password,
        extensionUsername: this.operator?.extension_username,
        firstName: this.operator?.user?.first_name,
        lastName: this.operator?.user?.last_name,
        iceServers: servers,
      },
      this._organization,
      (invitation) => {
        this.onInvite(invitation);
      },
      this.operator,
      voiceServerConfig
    );
    this.sipConnected = true;
  }

  async onInvite(invitation: Invitation) {
    if (invitation) {
      this.shouldByeCall = false;

      const operatorCanReceiveCall =
        await this.operatorIsEligibleToReceiveCalls();
      if (!operatorCanReceiveCall) {
        const microphonePermissionIsGranted =
          await this.checkMicrofonePermission();
        if (!microphonePermissionIsGranted) {
          this._toast.error(
            "Você deve permitir o BlueOne a utilizar seu microfone no navegador!"
          );
        }
        await invitation.reject({ statusCode: 486 });
        return;
      }

      const callId =
        invitation.request.getHeader("File-Name") ||
        invitation.request.getHeader("Unique-ID") ||
        invitation.request?.callId;

      this.uniqueIdFromSwitch = invitation.request.getHeader("X-Sipcallid");

      invitation.delegate = {
        onBye: (bye) => {
          this.shouldByeCall = true;
        },
      };

      invitation.stateChange.addListener((state) => {
        if (state === "Terminated") {
          this.currentIncomingCall = null;
          this.setGlobalIncomingCall(false);
        }
      });

      const { displayName } = invitation.remoteIdentity;
      const { displayName: fromDisplayName } = invitation.request.from;
      let queue = null;
      let conversation = null;

      const contactHeader = invitation.request.headers?.Contact[0];

      let phone =
        invitation.request.getHeader("X-Contact") ||
        fromDisplayName ||
        contactHeader?.parsed?.uri?.raw?.user ||
        displayName;

      const isConference =
        invitation.request.hasHeader("X-isConference") || false;

      const extensionHost = invitation.request.getHeader("X-extensionHost");
      const conferenceNumber =
        invitation.request.getHeader("X-conferenceNumber");

      const uniqueId = invitation.request.getHeader("X-Unique-ID");

      if (phone.indexOf(":") !== -1) {
        const [queueNumber, phoneNumber] = String(displayName)
          .trim()
          .split(":");
        queue = queueNumber;
        phone = phoneNumber;
      }

      if (phone.indexOf("#") !== -1) {
        const [queueNumber, phoneNumber] = String(displayName)
          .trim()
          .split("#");
        queue = queueNumber;
        phone = phoneNumber;
      }

      if (queue && this.operator.custom_data.administrative) {
        await invitation.reject({ statusCode: 480 });
        return;
      }

      if (queue && this.operator.custom_data.administrative) {
        await invitation.reject({ statusCode: 480 });
        return;
      }

      if (isConference)
        sessionStorage.setItem(
          "conferenceIncomingSession",
          JSON.stringify({
            isConference,
            extensionHost,
            conferenceNumber,
            phone,
          })
        );

      this.currentIncomingCall = {
        invitation,
        phone,
        queueName: queue,
        callId:
          this.voiceHelper.fetchVoiceDriver() === "freeswitch"
            ? this.uniqueIdFromSwitch
            : callId,
        conversationId: conversation,
        uniqueId,
      };

      this.setGlobalIncomingCall();
    }
  }

  async operatorIsEligibleToReceiveCalls(): Promise<boolean> {
    const operator = await this.operatorsService.mePromise(
      this._organization.id
    );
    this.operator = operator?.data;

    const conversations = await this.getQueue("me", this.user.id);
    this.conversations = conversations
      .flat()
      .map((el) => this.conversationsService.parseConversation(el));

    const activeVoiceConversationIndex = this.conversations.findIndex(
      (el) =>
        el.status === "in_progress" &&
        ["voice", "ura"].includes(el.channel?.slug)
    );

    const microphonePermissionIsGranted = await this.checkMicrofonePermission();

    return (
      this.currentConversation?.channel?.slug !== "voice" &&
      this.operator.status === "online" &&
      activeVoiceConversationIndex === -1 &&
      microphonePermissionIsGranted
    );
  }

  async checkMicrofonePermission(): Promise<boolean> {
    try {
      const permission = await navigator.permissions.query({
        name: "microphone",
      } as any);
      return permission.state === "granted";
    } catch (error) {
      return true;
    }
  }

  async onIncomingCallAccepted(call: IncomingCallParams) {
    if (!call) return;

    try {
      try {
        await this.handleStartCall(call);
      } catch (error) {
        if (error?.message === "call disconnected") {
          this._toast.warning("Houve uma desconexão da chamada!", "Atenção!", {
            timeOut: 7000,
          });
        } else {
          this._toast.warning(
            "Outro operador já iniciou esta chamada!",
            "Atenção!",
            { timeOut: 7000 }
          );
        }

        return;
      }

      let conversation: IConversation;

      if (call.invitation.request.getHeader("X-Conversationid")) {
        await this.conversationsService.updateGenesysConversation(
          this?._organization?.id,
          call.invitation.request.getHeader("X-Conversationid"),
          {
            mode: "operator",
            operator: this._auth.getUser().email,
          }
        );

        conversation = await this.getConversationById(
          call.invitation.request.getHeader("X-Conversationid")
        );
      } else {
        conversation = await this.createInboundConversation(
          call.phone,
          call.queueName
        );
      }

      const { data: currentCall } = await this.conversationsService.storeCall(
        this._organization.id,
        conversation.id,
        {
          direction: "inbound",
          phone: call?.phone,
          started_at: moment().toISOString(),
          status: "in_progress",
          reference: call?.callId,
          asterisk_uniqueid: call?.uniqueId,
        }
      );

      // Guarda o telefone acima criado para quando associar um contato
      this.phoneFromReceptiveCall = currentCall;

      this.currentInviter = call.invitation;
      this.currentConversation = {
        ...conversation,
        calls: new Array(currentCall),
      };

      this.currentConversation.contact = {
        ...(this.currentConversation.contact || {}),
        phones: this.parseContactPhones(
          this.currentConversation.contact?.phones,
          currentCall
        ),
      };

      this.inboundPhones = {
        inbounded: true,
        phone: this.parseContactPhones(
          this.currentConversation.contact?.phones,
          currentCall
        ),
      };

      const inboundPhone = this.inboundPhones.phone[0]
        ? this.inboundPhones.phone[0]
        : null;

      if (inboundPhone) {
        sessionStorage.setItem(
          "inboundPhone",
          JSON.stringify({
            call: inboundPhone?.call,
            callId: inboundPhone?.callId,
            callStatus: inboundPhone?.callStatus,
            id: inboundPhone?.id,
            isCurrentCall: inboundPhone?.isCurrentCall,
            phone: inboundPhone?.phone,
            status: inboundPhone?.status,
          })
        );
      }

      this.getCustomFieldsFormFromConversation();
      this.onChangeQueue(this.currentQueue);
    } catch (error) {
      console.error(error);
      this._toast.error(error.message, "Ops!");
      this.currentIncomingCall = null;
      this.setGlobalIncomingCall(false);
      this.currentConversation = null;
      this.currentInviter = null;
    }
  }

  async handleStartCall(call: IncomingCallParams) {
    try {
      await call?.invitation.accept();
    } catch (error) {
      this._toast.error(error.message, "SIP Erro ao começar a chamada");
      console.error("SIP - ERROR on accepting call", error);
      return;
    }

    if (call?.invitation?.state === "Terminated") {
      throw new Error("call disconnected");
    }

    this.currentConversation = {
      ...this.currentConversation,
      status: EConversationStatus.InProgress,
    };

    this.b1SipSetupRemoteMedia(call.invitation, call?.phone);

    call.invitation.stateChange.addListener((state: SessionState) => {
      if (state === SessionState.Terminated) {
        this.b1SipCleanupMedia(call?.phone);
        this.shouldByeCall = true;
      }
    });

    this.changeOperatorToOnCallStatus(this.operator);
  }

  getQueueByCallParams(queueId: string): string {
    return this.voiceHelper.fetchQueueIdByName(queueId);
  }

  changeOperatorToOnCallStatus(param = null): void {
    const operator = param || this.operator;
    if (operator) {
      this.operatorsService
        .updateStatusObserver(this?._organization?.id, param?.id, {
          status: STATUS_TYPE["in_call"],
          organization_id: this?._organization?.id,
          operator_name: `${param?.user?.first_name} ${param?.user?.last_name}`,
        })
        .subscribe();
    }
  }

  async createInboundConversation(
    phone: string,
    queueName: string
  ): Promise<IConversation> {
    const queueId = this.getQueueByCallParams(queueName);

    const ticket = await this.ticketsService
      .store(this._organization.id, {
        ticket_origin_id: "",
        user_id: this.user?.id,
        protocol: phone,
        title: phone,
        priority: "normal",
        status: "open",
        ticket_origin_name: "URA",
      })
      .toPromise();

    const { data } = await this.conversationsService
      .store(this._organization.id, {
        started_by: "customer",
        status: "queued",
        priority: "normal",
        channel: "voice",
        queue_id: queueId,
        autoIdentifyPhone: phone.replace(/\s/g, ""),
        ticket_id: ticket?.data?.id || null,
        contact_data: {
          initPhone: phone.replace(/\s/g, ""),
        },
      })
      .toPromise();

    const { data: conversationUpdated } = await this.conversationsService
      .updateStatus(this._organization.id, data?.id, { status: "in_progress" })
      .toPromise();

    return conversationUpdated;
  }

  parseContactPhones(contactPhones: any[] = [], currentCall: any): any[] {
    const currentPhoneIndex = contactPhones.findIndex((el) => {
      const phoneSanitized = String(el.phone)
        .replace(/ /g, "")
        .trim()
        .replace("+55", "")
        .replace(/^55/, "")
        .replace(/\D+/g, "");

      return phoneSanitized === currentCall.phone;
    });

    if (currentPhoneIndex === -1) {
      contactPhones.push(this.parsePhoneFromSip(currentCall));
    } else {
      contactPhones[currentPhoneIndex] = {
        ...this.parsePhoneFromSip(currentCall),
        ...contactPhones[currentPhoneIndex],
      };
    }

    return contactPhones;
  }

  parsePhoneFromSip(call) {
    return {
      id: Math.floor(Math.random() * 1000),
      phone: call?.phone,
      status: call?.status,
      callId:
        this.voiceHelper.fetchVoiceDriver() === "freeswitch"
          ? this.uniqueIdFromSwitch
          : call?.id,
      callStatus: call?.status === "in_progress" ? "in_progress" : null,
      call,
      isCurrentCall: true,
      inviter: this.currentInviter,
    };
  }

  async getConversations(phone: string): Promise<any> {
    const response = await this.conversationsService
      .filter(
        this._organization.id,
        {
          order_column: "conversations.started_at",
          order_type: "desc",
        },
        {
          contact: phone,
          statuses: ["queued"],
        }
      )
      .toPromise();

    return response;
  }

  async onIncomingCallDeclined(call: IncomingCallParams) {
    if (call) {
      await call.invitation.reject({ statusCode: 603 });
      this.currentIncomingCall = null;
      this.setGlobalIncomingCall(false);
    }
  }

  b1SipSetupRemoteMedia(
    inviter: Invitation & InviterWithPeerConnection,
    phone: string
  ): void {
    const remoteStream = new MediaStream();
    const audioElement = document.createElement("audio");
    audioElement.classList.add("d-none");
    audioElement.id = phone;
    document.getElementById("remoteAudioTracks").appendChild(audioElement);
    inviter.sessionDescriptionHandler.peerConnection
      .getReceivers()
      .forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });

    audioElement.srcObject = remoteStream;
    audioElement.play();
  }

  b1SipCleanupMedia(phone: string): void {
    const audioElement = document.getElementById(phone) as HTMLAudioElement;
    if (audioElement) {
      audioElement.srcObject = null;
      audioElement.pause();
    }
  }

  /**
   * getConversationById - fetch conversation by id
   * @param id conversation id
   */
  getConversationById(id: string): Promise<IConversation> {
    return new Promise((resolve) => {
      this.conversationsService.show(this._organization.id, id).subscribe(
        (res) => resolve(res.data),
        () => resolve(null)
      );
    });
  }

  returnClassByTabs(tab) {
    return {
      "show-Queue":
        this.startConversationTab !== "new" ||
        (this.organizationConversationTab !== null &&
          this.organizationConversationTab != tab),
      blue:
        this.startConversationTab === "new" &&
        this.organizationConversationTab === tab,
    };
  }

  toggleStartConversationTab(
    tab: NewConversationTab,
    orgTab: string = null
  ): void {
    this.showSearchResults = false;
    this.searchResults = null;
    this.contactSearchForm.reset();
    if (orgTab) {
      this.organizationConversationTab = orgTab;
    }
    this.startConversationTab = tab;
  }

  /**
   * connectWs - init websocket connection and listen events
   */
  connectWs() {
    this.ws = io(environment.wsUrl, {
      query: {
        room: [
          `call-center::organizations::${this._organization.id}`,
          `call-center::operators::${this.operator?.id}`,
          `call-center::operator::${this.operator?.id}`,
        ],
        authorization: `Bearer ${this._auth.getToken().token}`,
      },
    });

    this.ws.once("connect", () => {
      this.ws.on("new_conversation", (payload) => {
        if (payload?.id && !payload?.user_id) {
          const conversation = payload;

          const conversationData = {
            ...payload,
            contact: this.contactsService.parseContact(
              this._organization.slug,
              payload?.contact
            ),
          };

          if (this.currentQueue === "me") {
            if (conversation.operators && conversation.operators.length > 0) {
              const hasOperator = conversation.operators.find(
                (el) => el?.user?.id === this.user.id
              );

              if (hasOperator) {
                this.conversations.push(conversationData);
              }
            }
          } else if (this.currentQueue === "public") {
            if (
              !conversation.queue_id &&
              (!conversation.operators || conversation.operators.length === 0)
            ) {
              this.conversations.push(conversationData);
            }
          } else {
            if (this.currentQueue === conversation.queue_id) {
              this.shouldReloadQueues = true;
              this.conversations.push(conversationData);
              this.queuesService.handleUpdateQueue(this.conversations);
            }
          }
        }
      });

      this.ws.on("status_update", (payload) => {
        if (payload?.id && payload?.status === "in_progress") {
          const amIOperator = payload?.operators.find(
            (el) => el.id === this.operator?.id
          );

          if (!amIOperator) {
            this.conversations = this.conversations.filter(
              (el) => el?.id !== payload?.id
            );
          }
        }

        if (payload?.id) {
          const index = this.conversations.findIndex(
            (el) => el.id === payload.id
          );

          if (index !== -1) {
            this.shouldReloadQueues = true;
            this.conversations[index] = payload;

            if (
              this.currentConversation &&
              this.currentConversation.id === payload.id
            ) {
              this.currentConversation = payload;
              this.getCustomFieldsFormFromConversation();
            }
          }
        }
      });

      this.ws.on("deleted", (payload) => {
        this.shouldReloadQueues = true;

        if (payload?.id) {
          this.conversations = this.conversations.filter(
            (el) => el.id !== payload.id
          );

          if (
            this.currentConversation &&
            this.currentConversation.id === payload.id
          ) {
            this._toast.error("Atendimento encerrado.");
            this.currentConversation = null;
            this.getCustomFieldsFormFromConversation();
          }
        }
      });

      this.ws.on("new_to_operator", (payload) => {
        this.shouldReloadQueues = true;

        if (payload?.id) {
          const conversation =
            this.conversationsService.parseConversation(payload);

          if (!this.currentConversation) {
            this.conversations.push(conversation);
            this.currentConversation = conversation;
            this.getCustomFieldsFormFromConversation();
            this.onStartConversation(conversation);
          }
        }
      });

      this.ws.on("new_conversation_by_operator", (payload) => {
        const conversation = payload;

        const conversationData: IConversation = {
          ...payload,
          contact: this.contactsService.parseContact(
            this._organization.slug,
            payload?.contact
          ),
        };

        if (
          payload?.id &&
          conversationData?.operators.find(({ id }) => this.operator?.id === id)
        ) {
          if (this.currentQueue === conversation.queueId) {
            this.shouldReloadQueues = true;
            this.conversations.push(conversationData);
            this.queuesService.handleUpdateQueue(this.conversations);
          }
        } else {
        }
      });
    });
  }

  /**
   * getId - get conversation id from url params
   */
  getId(): Promise<string> {
    return new Promise((resolve) => {
      const { id } = this._activatedRoute.snapshot.params;
      resolve(id);
    });
  }

  /**
   * getCurrentConversation - return in progress current conversation
   */
  getCurrentConversation(): IConversation {
    const inProgressConversations = this.conversations.filter(
      (el) => el.status === "in_progress"
    );
    if (inProgressConversations.length > 0) {
      const conversationInStorage = localStorage.getItem("@ecp/conversation");

      if (conversationInStorage) {
        const conversation = inProgressConversations.find(
          (el) => el.id === conversationInStorage
        );

        if (conversation) {
          return conversation;
        } else {
          localStorage.removeItem("@ecp/conversation");
        }
      }

      return inProgressConversations[0];
    }

    return null;
  }

  /**
   * Fetch queue conversations
   * @param type queue type
   * @param userId user id
   * @param queueId queue id
   */
  async getQueue(
    type: "queue" | "public" | "me",
    userId: string = null,
    queueId: string = null
  ): Promise<Array<IConversation>> {
    const queuesToGetOnlyTodaySchedules = [
      "6ff0d226-31fa-431b-bc4d-c9feee9b151e",
      "6ac240e0-6087-4b45-b742-0bb21193f729",
    ];

    const queueOptions = {
      only_today: queuesToGetOnlyTodaySchedules.includes(queueId),
    };

    const promisesByQueueType = {
      public: this.queuesService.publicConversations(this._organization.id),
      me: this.queuesService.conversationsByOperator(
        this._organization.id,
        userId
      ),
      queue: this.queuesService.conversationsByQueue(
        this._organization.id,
        queueId,
        queueOptions
      ),
    };

    if (!promisesByQueueType[type]) return [];
    const { data } = await promisesByQueueType[type].toPromise();
    return data;
  }

  /**
   * Fetch queues with in progress conversations
   */
  getInProgress(): Promise<Array<IConversation>> {
    return new Promise((resolve) => {
      this.queueService
        .conversations(this._organization.id, { type: "in_progress" })
        .subscribe(
          (res) => resolve(res.data),
          () => resolve([])
        );
    });
  }

  /**
   * Toggle customer details tabs
   * @param tab tab slug
   */
  toggleTab(tab: string): void {
    this.tab = tab;

    if (this.tab === "chat") {
      this.getMessages();
    }
  }

  /**
   * Fetch chat messages
   */
  async getMessages() {
    if (this.currentConversation) {
      this.currentConversation.messages = await this.loadConversationMessages(
        this.currentConversation.id
      );
    }
  }

  /**
   * Get chat messages
   */
  loadConversationMessages(id: string): Promise<Array<IConversationMessage>> {
    return new Promise((resolve) => {
      this.conversationsService
        .listMessages(this._organization.id, id)
        .subscribe(
          (res) => resolve(res.data),
          () => resolve([])
        );
    });
  }

  /**
   * Event when the current conversation changes
   * @param conversation conversation object
   */
  onToggleConversation(conversation: IConversation): void {
    this.currentConversation = null;
    this.customFieldsForm = null;
    this.tab = "customer-details";
    this.currentConversation = conversation;
    this.getCustomFieldsFormFromConversation();
    this.showTabulationForm = false;
  }

  getCustomFieldsFormFromConversation() {
    if (this.currentConversation) {
      this.customFieldsForm = this.currentConversation?.custom_fields || {};

      if (this._organization.slug === "medlevensohn") {
        this.customFieldsForm =
          this.currentConversation?.contact?.profile?.data || {};
      }
    } else {
      this.customFieldsForm = {};
    }
  }

  /**
   * Event when user starts a conversation
   * @param conversation conversation object
   */
  async onStartConversation(
    conversation: IConversation,
    isVoiceConversation?: boolean
  ) {
    try {
      if (conversation.status === EConversationStatus.InProgress) {
        const generateCurrentDate = moment
          .tz("America/Sao_Paulo")
          .toISOString();

        const contactData = conversation?.contact
          ? this.contactsService.parseContact(
              this._organization.slug,
              conversation?.contact
            )
          : null;

        const ifIsVoiceAndContactNotExistUseSearchedNumber =
          this.searchData && isVoiceConversation && !contactData?.phones
            ? [
                {
                  id: "",
                  phone: this.searchData,
                  has_whatsapp: true,
                  data: {},
                  created_at: generateCurrentDate,
                  updated_at: generateCurrentDate,
                  is_main: false,
                  type: "other",
                  director: null,
                  callStatus: null,
                  status: "idle",
                  call: null,
                },
              ]
            : [...(contactData?.phones || [])];

        this.currentConversation = {
          ...conversation,
          contact: {
            ...(contactData || {}),
            phones: ifIsVoiceAndContactNotExistUseSearchedNumber,
          },
          searchQuery: this.searchData,
        };

        this.getCustomFieldsFormFromConversation();
        this.onChangeQueue(this.currentQueue);
      }
    } catch (err) {
      console.log("Failed on status");
    }
  }

  /**
   * Event when user finishes a conversation
   */
  async onFinishConversation() {
    try {
      if (this._organization.slug === "mapfre") {
        if (
          !this.contactForm.value?.first_name ||
          this.contactForm.value?.first_name.length === 0
        ) {
          throw new Error(`Nome é obrigatório.`);
        }

        if (
          !this.contactForm.value?.last_name ||
          this.contactForm.value?.last_name.length === 0
        ) {
          throw new Error(`Sobre nome é obrigatório.`);
        }

        if (
          !this.contactForm.value?.cpf ||
          this.contactForm.value?.cpf.length === 0
        ) {
          throw new Error(`CPF é obrigatório.`);
        }

        if (
          !this.customFieldsForm?.Serviço ||
          this.customFieldsForm?.Serviço.length === 0
        ) {
          throw new Error(`Serviço é obrigatório.`);
        }

        if (
          !this.customFieldsForm?.Solicitante ||
          this.customFieldsForm?.Solicitante.length === 0
        ) {
          throw new Error(`Solicitante é obrigatório.`);
        }

        if (
          !this.customFieldsForm?.["Tipo de assistência"] ||
          this.customFieldsForm?.["Tipo de assistência"].length === 0
        ) {
          throw new Error(`Tipo de assistência é obrigatório.`);
        }

        if (
          !this.customFieldsForm?.["Tipo de autorização"] ||
          this.customFieldsForm?.["Tipo de autorização"].length === 0
        ) {
          throw new Error(`Tipo de autorização é obrigatório.`);
        }

        if (
          !this.customFieldsForm?.["Cliente Mapfre"] ||
          this.customFieldsForm?.["Cliente Mapfre"].length === 0
        ) {
          throw new Error(`Cliente Mapfre é obrigatório.`);
        }
      }

      if (this._organization.slug === "mapfre-nissan-renault") {
        if (
          !this.contactForm.value?.first_name ||
          this.contactForm.value?.first_name.length === 0
        ) {
          throw new Error(`Nome é obrigatório.`);
        }

        if (
          !this.contactForm.value?.last_name ||
          this.contactForm.value?.last_name.length === 0
        ) {
          throw new Error(`Sobrenome é obrigatório.`);
        }
      }

      if (this._organization.slug === "jaguar") {
        if (
          (!this.customFieldsForm?.["Nº CXP/Salesforce"] ||
            this.customFieldsForm?.["Nº CXP/Salesforce"].length === 0) &&
          this.contactForm.value?.first_name
        ) {
          throw new Error(`Nº CXP/Salesforce é obrigatório.`);
        }
      }

      this.toggleTabulationForm();
    } catch (error) {
      this._toast.error(error?.message || String(error));
    }
  }

  async onCancelConversation() {
    this.showCancelConversation = true;
  }

  /**
   * Toggle conversation tabulation
   */
  toggleTabulationForm() {
    this.showTabulationForm = !this.showTabulationForm;
  }

  /**
   * Event when user closes the conversation tabulation
   */
  onCloseTabulationForm() {
    this.showTabulationForm = false;
  }

  /**
   * Event when user finishes the current conversation
   * @param conversation conversation object
   */
  async onFinishCurrentConversation(
    conversation: IConversation,
    shouldAskConfirmation = true
  ) {
    let shouldFinish = true;

    if (shouldAskConfirmation) {
      const { value } = await this._swal.fire({
        title: "Confirmar finalização",
        text: "Você tem certeza que deseja finalizar este atendimento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Finalizar!",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn--Danger ml-2",
        reverseButtons: true,
      });

      shouldFinish = value;
    }

    if (shouldFinish) {
      this.loadingTabulation = true;

      try {
        this.shouldByeCall = true;
        conversation.status = EConversationStatus.Finished;
        conversation.finished_at = moment().format();
        const promises = [];

        if (this.currentConversation?.contact_id) {
          let contactDefaultData = {
            first_name: this.contactForm.value?.first_name,
            last_name: this.contactForm.value?.last_name,
            cpf: this.contactForm?.value.cpf,
            rg: this.contactForm?.value.rg,
            birthday_at: this.contactForm.value?.birthdate_at,
          };

          let customData: any = {};

          if (this._organization.slug === "mapfre-nissan-renault") {
            customData = {
              mapfre: this.organizationConversationTab,
            };
          }

          if (this._organization.slug === "auphafood") {
            customData = {
              auphafood_origin: this.contactForm.value.auphafood_origin,
              auphafood_expectations:
                this.contactForm.value.auphafood_expectations,
            };
          }

          if (this._organization.slug === "neobpo") {
            customData = {
              installation_number: this.contactForm.value.installation_number,
            };
          }

          if (
            this._organization.slug !== "ecp" &&
            this._organization.slug !== "vr"
          ) {
            promises.push(
              this.contactsService.updatePromise(
                this._organization.id,
                this.currentConversation?.contact_id,
                {
                  ...contactDefaultData,
                  ...customData,
                },
                {
                  requestOrigin: "frontend",
                }
              )
            );
          }
        }

        await Promise.all([
          ...promises,
          this.updateConversationStatus(
            conversation.id,
            conversation.status,
            conversation.call_status
          ),
          this.onChangeQueue(this.currentQueue),
        ]);

        localStorage.removeItem("@ecp/conversation");
        localStorage.removeItem("@mapfre-nissan-renault/customOrg");
        sessionStorage.removeItem("inboundPhone");
        sessionStorage.removeItem("outboundPhone");
        this.customFieldsForm = {};
        this.currentConversation = null;
        this.getCustomFieldsFormFromConversation();
        this._toast.success("Atendimento finalizado!", "Sucesso", {
          timeOut: 2000,
        });
        this.loadingTabulation = false;
        this.showTabulationForm = false;
        this.actualPhonesUpdated = null;
        this.searchData = null;
        this.onChangeQueue(this.currentQueue);
      } catch (error) {
        this.loadingTabulation = false;
        this._toast.error(
          "[EC01] Houve um erro ao finalizar o atendimento, tente novamente."
        );
      }
    }
  }

  /**
   * Update ECP associate
   * @param id ecp associate id
   * @param data data payload
   */
  updateEcpContact(id: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ecpService.update(this._organization.id, id, data).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  /**
   * Update conversation status
   * @param id conversation id
   * @param status new status
   */
  updateConversationStatus(
    id,
    status,
    call_status = null
  ): Promise<IConversation> {
    return new Promise((resolve, reject) => {
      this.conversationsService
        .updateStatus(this._organization.id, id, { status, call_status })
        .subscribe(
          (res) =>
            resolve(this.conversationsService.parseConversation(res.data)),
          (err) => reject(err)
        );
    });
  }

  updateConversation(id, data): Promise<IConversation> {
    return new Promise((resolve, reject) => {
      this.conversationsService
        .update(this._organization.id, id, data)
        .subscribe(
          (res) =>
            resolve(this.conversationsService.parseConversation(res.data)),
          (err) => reject(err)
        );
    });
  }

  /**
   * Remove conversation from queues
   * @param conversation conversation object
   */
  removeConversation(conversation: IConversation): void {
    this.conversations = this.conversations.filter(
      (el) => el.id !== conversation.id
    );
  }

  /**
   * Add a conversation to queue
   * @param conversation conversation object
   */
  async addConversation(conversation: IConversation) {
    const index = this.conversations.findIndex(
      (el) => el.id === conversation.id
    );

    if (index === -1) {
      this.conversations.unshift(conversation);
    }
  }

  /**
   * Update a conversation in queue
   * @param conversation conversation object
   */
  updateConversationState(conversation: IConversation): void {
    const index = this.conversations.findIndex(
      (el) => el.id === conversation.id
    );
    this.conversations[index] = conversation;
  }

  /**
   * Event called when user associates a contact to current conversation
   * @param conversation conversation object
   */
  onAssociateContact(conversation: IConversation) {
    this.currentConversation = {
      ...conversation,
      contact: this.contactsService.parseContact(
        this._organization.slug,
        conversation.contact
      ),
    };

    // Procura o atendimento que caiu na URA e atualiza o status dele após vincular o contato

    if (
      this.currentConversation?.phoneWhatComesFirst &&
      this.phoneFromReceptiveCall &&
      ["ecp", "jaguar"].includes(this._organization.slug)
    ) {
      this.currentConversation.contact = {
        ...(this.currentConversation.contact || {}),
        phones: this.parseContactPhones(
          this.currentConversation?.contact?.phones,
          this.phoneFromReceptiveCall
        ),
      };
    }

    this.getCustomFieldsFormFromConversation();
    this.updateConversationState(this.currentConversation);
  }

  handleLinkContact() {
    this.showSearchResults = false;

    const messageSyncToast = this.attemptLink === 0 ? "" : "novamente";
    this._toast.info(
      `Por favor aguarde. Estamos ${messageSyncToast} sincronizando o contato.`
    );

    setTimeout(() => {
      this.attemptLink++;
      if (this.attemptLink <= 1) {
        this.handleContactSearch();
      } else {
        this._toast.warning(
          "Um dos contatos não foi sincronizado corretamente. Entre em contato com suporte Blueone."
        );
      }
      this._toast.info("Contato sincronizado. Atualizando lista.");
    }, 10000);
  }

  /**
   * Search for a contact
   */
  handleContactSearch() {
    if (this.contactSearchForm.valid) {
      this.searching = true;
      this.searchData = this.contactSearchForm.value.query;

      if (this._organization.slug === "ecp") {
        this.contactsService
          .listLightEcp(this._organization.id, {
            query: this.contactSearchForm.value.query,
          })
          .subscribe(
            (res) => {
              this.searching = false;
              const contactToLink = res.data.filter((el) => !el.contact_id);

              if (contactToLink.length) {
                contactToLink.map(() => this.handleLinkContact());
              } else {
                this.attemptLink = 0;
              }

              this.searchResults = (res.data || []).map((el) =>
                this.conversationsService.parseContactEcpLight(el)
              );
              this.showSearchResults = true;
            },
            () => {
              this.searching = false;
              this._toast.error(
                "Houve um erro ao realizar a pesquisa, tente novamente"
              );
            }
          );
      } else {
        this.contactsService
          .list(this._organization.id, {
            query: this.contactSearchForm.value.query,
          })
          .subscribe(
            (res) => {
              this.searching = false;
              this.searchResults = (res.data || []).map((el) =>
                this.conversationsService.parseContact(el)
              );
              this.showSearchResults = true;
            },
            () => {
              this.searching = false;
              this._toast.error(
                "Houve um erro ao realizar a pesquisa, tente novamente"
              );
            }
          );
      }
    }
  }

  async handleEcpWorkflowDatabaseContactSearch() {
    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para iniciar um atendimento!"
      );
      return;
    }
    this.searching = true;

    const { query, database } = this.contactSearchForm.value;
    if (!query) {
      this._toast.error("É obrigatório preencher o campo de pesquisa");
      this.searching = false;
      return;
    }
    if (!database || !database?.length) {
      this._toast.error("É obrigatório selecionar os locais para busca");
      this.searching = false;
      return;
    }

    const params: SearchOnEcpDatabaseParamsModel = {
      entity: database.join(","),
    };

    if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(query)) {
      params.nome = query;
    } else if (query.length === 8) {
      params.matricula = query;
    } else {
      params.rg = query;
    }

    try {
      const httpResponse = await this.ecpService.searchOnEcpDatabase(
        this._organization.id,
        params
      );
      const contacts: IContact[] = [];

      if (httpResponse.associado) {
        contacts.push(
          ...httpResponse.associado
            .filter((el) => el.SituacaoCadastral === "Ativo")
            .map((el) => {
              const splittedName = [
                el.Nome.replace(/\s.*/, ""),
                el.Nome.replace(/\S+\s/, ""),
              ];
              return {
                id: el.ID,
                reference: el.ID,
                organization_id: this._organization.id,
                organization: this._organization,
                profile: {
                  first_name: splittedName[0],
                  last_name: splittedName[1],
                  cpf: el.CPF,
                  birthdate_at: el.DataNascimento,
                  registration_number: el.Matricula,
                },
                deleted: false,
                phones: [],
                display_name: el.Nome,
                type: "associado",
              };
            })
        );
      }

      if (httpResponse.acompanhante) {
        contacts.push(
          ...httpResponse.acompanhante.map((el) => {
            const splittedName = [
              el.NomeAcompanhante.replace(/\s.*/, ""),
              el.NomeAcompanhante.replace(/\S+\s/, ""),
            ];
            return {
              id: el.IdAcompanhante,
              reference: el.IdAcompanhante,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.DocumentoAcompanhante,
                birthdate_at: el.DataNascimento,
                registration_number: el.IdAcompanhante,
              },
              original: el,
              deleted: false,
              phones: [],
              display_name: el.NomeAcompanhante,
              type: "acompanhante",
            };
          })
        );
      }

      if (httpResponse.convidado) {
        contacts.push(
          ...httpResponse.convidado.map((el) => {
            const splittedName = [
              el.NomeAcompanhante.replace(/\s.*/, ""),
              el.NomeAcompanhante.replace(/\S+\s/, ""),
            ];
            return {
              id: el.IdAcompanhante,
              reference: el.IdAcompanhante,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.DocumentoAcompanhante,
                birthdate_at: el.DataNascimento,
                registration_number: el.IdAcompanhante,
              },
              original: el,
              deleted: false,
              phones: [],
              display_name: el.NomeAcompanhante,
              type: "convidado",
            };
          })
        );
      }

      if (httpResponse.visitante) {
        contacts.push(
          ...httpResponse.visitante.map((el) => {
            const splittedName = [
              el.Nome.replace(/\s.*/, ""),
              el.Nome.replace(/\S+\s/, ""),
            ];
            return {
              id: el.Id,
              reference: el.Id,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.Documento,
                birthdate_at: el.DataNascimento,
                registration_number: el.Id,
              },
              deleted: false,
              phones: [],
              display_name: el.Nome,
              type: "visitante",
            };
          })
        );
      }

      if (httpResponse.torcida) {
        contacts.push(
          ...httpResponse.torcida.map((el) => {
            const splittedName = [
              el.Nome.replace(/\s.*/, ""),
              el.Nome.replace(/\S+\s/, ""),
            ];
            return {
              id: el.Id,
              reference: el.Id,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.Documento,
                birthdate_at: el.DataNascimento,
                registration_number: el.Id,
              },
              deleted: false,
              phones: [],
              display_name: el.Nome,
              type: "torcida",
            };
          })
        );
      }

      if (httpResponse.prestador) {
        contacts.push(
          ...httpResponse.prestador.map((el) => {
            const splittedName = [
              el.Nome.replace(/\s.*/, ""),
              el.Nome.replace(/\S+\s/, ""),
            ];
            return {
              id: el.Matricula,
              reference: el.Matricula,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.Documento,
                birthdate_at: el.DataNascimento,
                registration_number: el.Matricula,
              },
              deleted: false,
              phones: [],
              display_name: el.Nome,
              type: "prestador",
            };
          })
        );
      }

      if (httpResponse.funcionario) {
        contacts.push(
          ...httpResponse.funcionario.map((el) => {
            const splittedName = [
              el.Nome.replace(/\s.*/, ""),
              el.Nome.replace(/\S+\s/, ""),
            ];
            return {
              id: el.Matricula,
              reference: el.Matricula,
              organization_id: this._organization.id,
              organization: this._organization,
              profile: {
                first_name: splittedName[0],
                last_name: splittedName[1],
                cpf: el.Documento,
                birthdate_at: el.DataNascimento,
                registration_number: el.Matricula,
              },
              deleted: false,
              phones: [],
              display_name: el.Nome,
              type: "funcionario",
            };
          })
        );
      }

      contacts.forEach((contact) => {
        if (!contact.profile.birthdate_at) return;

        const birthdate = contact.profile.birthdate_at;
        const age = Math.abs(
          new Date(birthdate).getTime() - new Date().getTime()
        );
        const years = Math.ceil(age / (1000 * 60 * 60 * 24 * 365));
        const isMinor = years <= 18 || years === null ? true : false;

        contact.profile.isMinor = isMinor;
      });

      this.searchResults = contacts;
      this.showSearchResults = true;
      this.searching = false;
    } catch (error) {
      this.searching = false;
      this._toast.error(error.message, "Ocorreu um erro");
    }
  }

  /**
   * Clear current search and results
   */
  handleClearSearch() {
    this.showSearchResults = false;
    this.contactSearchForm.reset();
  }

  /**
   * Hide current conversation
   */
  closeCurrentConversation() {
    this.currentConversation = null;
    this.getCustomFieldsFormFromConversation();
    this.showTabulationForm = false;

    if (this._activatedRoute.snapshot.params.id) {
      this.location.replaceState("call-center/operator");
    }
  }

  /**
   * Event called when a new conversation arrives or is stored
   */
  onNewConversation(conversation: IConversation) {
    this.currentConversation = conversation;
    this.getCustomFieldsFormFromConversation();
    this.updateConversationState(conversation);
    this.showTabulationForm = false;
  }

  /**
   * Event called when user open a contact profile
   * @param contact contact object
   */
  async onOpenContact(contact: IContact) {
    this.tab = "customer-details";
    this.loadingTabulation = true;

    try {
      const contactData: any =
        this._organization.slug !== "ecp"
          ? await this.contactsService
              .show(this._organization.id, contact.id)
              .toPromise()
          : {};

      this.currentConversation = {
        id: null,
        started_by: EConversationStartedBy.Operator,
        duration: null,
        started_at: null,
        finished_at: null,
        contact_data: {},
        contact: contactData?.data || contactData,
        contact_id: contact.id,
        priority: EConversationPriority.Normal,
        status: EConversationStatus.Queued,
        created_at: null,
        updated_at: null,
      };
      this.getCustomFieldsFormFromConversation();
      this.showTabulationForm = false;
      this.loadingTabulation = false;
    } catch (error) {
      this._toast.error(
        "Erro",
        "Houve um erro ao carregar os dados do contato, tente novamente."
      );
      this.loadingTabulation = false;
    }
  }

  /**
   * Navigate to conversation schedules list
   */
  loadQueue(mode = "presential") {
    if (mode === "sauna") {
      this.router.navigateByUrl("/service-scheduler");
    } else if (mode === "presential") {
      this.router.navigateByUrl("/call-center/schedules");
    }
  }

  /**
   * Handle queue select and load it's conversations
   * @param queue queue name
   */
  async onChangeQueue(queue: string) {
    this.currentQueue = queue;
    this.isLoadingQueue = true;

    try {
      if (queue === "public") {
        const conversations = await this.getQueue("public");
        this.conversations = conversations
          .flat()
          .map((el) => this.conversationsService.parseConversation(el));
      } else if (queue === "me") {
        const conversations = await this.getQueue("me", this.user.id);
        this.conversations = conversations
          .flat()
          .map((el) => this.conversationsService.parseConversation(el));
      } else {
        const conversations = await this.getQueue("queue", null, queue);
        this.conversations = conversations
          .flat()
          .map((el) => this.conversationsService.parseConversation(el));
      }

      this.conversations.forEach((conversation) => {
        const { contact } = conversation;
        if (!contact?.profile?.birthday_at) {
          return;
        } else if (!contact?.profile?.data) {
          return;
        } else if (!contact?.profile?.data?.birthdate_at) {
          return;
        }

        let birthdate =
          contact?.profile?.birthday_at || contact?.profile?.data?.birthdate_at;

        const age = Math.abs(
          new Date(birthdate).getTime() - new Date().getTime()
        );
        const years = Math.ceil(age / (1000 * 60 * 60 * 24 * 365));
        const isMinor = years <= 18 || years === null ? true : false;

        conversation.contact.profile.isMinor = isMinor;
      });

      this.isLoadingQueue = false;
    } catch (error) {
      console.error(error);
      this._toast.error("Erro ao carregar a fila, tente novamente.");
      this.isLoadingQueue = false;
    }
  }

  /**
   * Navigate to totem to allow schedule a conversation
   */
  handleSchedule() {
    window.open("/call-center/totem", "_blank");
  }

  /**
   * Event called when there's a change in contact phones
   * @param phones phones array
   */
  onUpdatedPhones(phones: IPhone[]): void {
    if (this.currentConversation?.contact) {
      this.currentConversation.contact.phones = phones;
      this.actualPhonesUpdated = phones;
    }
  }

  onUpdatedEmails(emails: any[]): void {
    if (this.currentConversation?.contact) {
      this.currentConversation.contact.emails = emails;
    }
  }

  onUpdatedContact(contact: IContact): void {
    if (this.currentConversation?.contact) {
      this.currentConversation.contact = contact;
    }
  }

  async handleCreateConversation(field: string): Promise<void> {
    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para iniciar um atendimento!"
      );
      return;
    }

    if (this.loadingConversations || this.creatingConversation) {
      return;
    }

    this.creatingConversation = true;

    if (this.contactSearchForm.valid) {
      try {
        const { query } = this.contactSearchForm.value;
        this.searchData = query;
        let contact = null;
        try {
          const response = await this.conversationsService
            .findContactByCustomField(this._organization.id, {
              field,
              value: query,
            })
            .toPromise();

          contact = response.data;
        } catch (err) {
          console.warn("contact_not_found");
        }

        const ticket = await this.ticketsService
          .store(this._organization.id, {
            ticket_origin_id: "",
            user_id: this.user?.id,
            protocol: query,
            title: query,
            priority: "normal",
            status: "open",
            ticket_origin_name: "CRM",
          })
          .toPromise();
        const custom_fields: any = {};
        if (field !== "phone") {
          custom_fields[field] = query;
        }
        const { data } = await this.conversationsService
          .store(this._organization.id, {
            autoIdentifyPhone: query,
            user_id: this.user?.id,
            queue_id:
              this._organization.slug === "mapfre"
                ? "464b7577-e3f7-421a-bcba-848e685323d4"
                : this.organizationConversationTab === "renault"
                ? "a8a2812f-bfa3-4da0-b9b4-eaccab992166"
                : "0cc62088-0296-459c-b55c-886f9e41cd66",
            started_by: "operator",
            started_at: moment.tz("America/Sao_Paulo").toISOString(),
            custom_fields,
            contact_data: {
              organization: this.organizationConversationTab,
              initPhone: query,
            },
            channel: "crm",
            ticket_id: ticket?.data?.id,
            contact_id: contact ? contact.id : null,
          })
          .toPromise();
        const conversation = await this.conversationsService
          .updateStatus(this._organization.id, data?.id, {
            status: "in_progress",
          })
          .toPromise();
        this.onStartConversation(
          this.conversationsService.parseConversation(conversation?.data)
        );
        this.creatingConversation = false;
      } catch (error) {
        this.creatingConversation = false;
      }
    }
  }

  async handleCreateVoiceConversation(queueId: string = null): Promise<void> {
    const { query } = this.contactSearchForm.value;
    if (
      !String(query).startsWith("0800") &&
      !validator.isMobilePhone(String(query), ["pt-BR"])
    ) {
      this._toast.error(
        "Por favor, digite um número de telefone válido!",
        "Ops!"
      );
      return;
    }

    this.searching = true;

    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para iniciar um atendimento!"
      );
      this.searching = false;
      return;
    }

    if (this.loadingConversations || this.creatingConversation) {
      this.searching = true;
      return;
    }

    this.creatingConversation = true;

    if (this.contactSearchForm.valid) {
      try {
        this.searchData = query;

        let ticket = null;

        if (this._organization.slug !== "vr") {
          ticket = await this.ticketsService
            .store(this._organization.id, {
              ticket_origin_id: "",
              user_id: this.user?.id,
              protocol: query,
              title: query,
              priority: "normal",
              status: "open",
              ticket_origin_name: "CRM",
            })
            .toPromise();
        }

        const { data } = await this.conversationsService
          .store(this._organization.id, {
            autoIdentifyPhone: query,
            user_id: this.user?.id,
            started_by: "operator",
            started_at: moment.tz("America/Sao_Paulo").toISOString(),
            channel: "voice",
            ticket_id: ticket?.data?.id || null,
            queue_id: queueId,
            contact_data: {
              initPhone: query,
            },
          })
          .toPromise();

        const conversation = await this.conversationsService
          .updateStatus(this._organization.id, data?.id, {
            status: "in_progress",
          })
          .toPromise();

        this.onStartConversation(
          this.conversationsService.parseConversation(conversation?.data),
          true
        );

        this.creatingConversation = false;
        this.contactSearchForm.reset();
        this.searching = false;
        this.searchData = null;
      } catch (error) {
        this.searching = false;
        this.creatingConversation = false;
        if (error?.error?.message === "IS_NOT_MINE_CONTACT_PARTNER") {
          this._toast.error("Contato vinculado a outro Gerente Comercial");
        }
      }
    }
  }

  async handleCreateAnonymousConversation(
    queueId: string = null
  ): Promise<void> {
    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para iniciar um atendimento!"
      );
      return;
    }

    if (this.loadingConversations || this.creatingConversation) {
      return;
    }

    this.creatingConversation = true;

    try {
      let ticket;
      if (this._organization.slug === "workflow") {
        const { data: queue } = await this.queueService
          .list(this._organization.id, {
            unique: true,
            name: "Encarregado de Segurança",
          })
          .toPromise();

        queueId = queue.id;

        const { data } = await this.ticketsService
          .store(this._organization.id, {
            user_id: this._auth.getUser().id,
            title: "Registro de Ocorrência",
            priority: "normal",
            status: "open",
            ticket_origin_name: "CRM",
            conversation_queue_id: queue?.id,
          })
          .toPromise();

        ticket = data;
      }

      const { data } = await this.conversationsService
        .store(this._organization.id, {
          user_id: this.user?.id,
          started_by: "operator",
          started_at: moment.tz("America/Sao_Paulo").toISOString(),
          channel: "voice",
          ticket_id: ticket ? ticket.id : null,
          queue_id: queueId,
        })
        .toPromise();
      const conversation = await this.conversationsService
        .updateStatus(this._organization.id, data?.id, {
          status: "in_progress",
        })
        .toPromise();
      this.onStartConversation(
        this.conversationsService.parseConversation(conversation?.data)
      );
      this.creatingConversation = false;
    } catch (error) {
      this.creatingConversation = false;
    }
  }

  async handleCreateAnonymousConversationWorkflow(
    queueId: string = null
  ): Promise<void> {
    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para iniciar um atendimento!"
      );
      return;
    }

    if (this.loadingConversations || this.creatingConversation) {
      return;
    }

    this.creatingConversation = true;

    try {
      const { data: queue } = await this.queueService
        .list(this._organization.id, {
          unique: true,
          name: "Encarregado de Segurança",
        })
        .toPromise();

      queueId = queue.id;

      const { data: ticket } = await this.ticketsService
        .store(this._organization.id, {
          user_id: this._auth.getUser().id,
          title: "Registro de Ocorrência",
          priority: "normal",
          status: "open",
          ticket_origin_name: "CRM",
          conversation_queue_id: queue?.id,
        })
        .toPromise();

      const { data: contact } = await this.contactsService
        .store(this._organization.id, {
          first_name: "Ticket",
          last_name: ticket.protocol,
        })
        .toPromise();

      const { data } = await this.conversationsService
        .store(this._organization.id, {
          user_id: this.user?.id,
          started_by: "operator",
          started_at: moment.tz("America/Sao_Paulo").toISOString(),
          channel: "voice",
          ticket_id: ticket ? ticket.id : null,
          queue_id: queueId,
          contact_id: contact.id,
        })
        .toPromise();

      const conversation = await this.conversationsService
        .updateStatus(this._organization.id, data?.id, {
          status: "in_progress",
        })
        .toPromise();

      this.onStartConversation(
        this.conversationsService.parseConversation(conversation?.data)
      );

      this.creatingConversation = false;
    } catch (error) {
      this.creatingConversation = false;
    }
  }

  async loadQueues() {
    this.queues = await this.getQueues();
  }

  getQueues(): Promise<Array<IConversationQueue>> {
    return new Promise((resolve) => {
      return this.queuesService
        .list(this._organization.id, { me: true })
        .subscribe(
          (res) => resolve(res.data),
          () => resolve([])
        );
    });
  }

  async onConversationCanceled() {
    if (this.operator.status !== "online") {
      this._toast.error(
        "Você precisa estar online para cancelar um atendimento!"
      );
      return;
    }

    this.currentConversation.user_id = this.operator.user_id;
    this.currentConversation = null;
    this.customFieldsForm = {};
    this.loadingTabulation = false;
    this.showTabulationForm = false;
    this.actualPhonesUpdated = null;
    this.searchData = null;
    this._toast.success(
      "O atendimento foi cancelado com sucesso!",
      "Atendimento cancelado"
    );
    this.getCustomFieldsFormFromConversation();
    this.onChangeQueue(this.currentQueue);
  }

  handleConversationChosen(conversation: IConversation): void {
    if (conversation?.id === this.currentConversation?.id) {
      return;
    }

    this.router.navigate([
      "/call-center/operator/conversation",
      conversation?.id,
    ]);
  }
}
